import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { graphql } from 'gatsby';

import SEO from '../components/SEO';
import Layout from '../components/Layout';
import { HeroContainer } from '../components/Containers';
import { Collage } from '../components/Image';
import { TextBlock, TextBlockDescription } from '../components/Text';
import EmbedContainer from '../components/EmbedContainer';

const PropertyDetailsHeading = styled.h5`
    color: green;
    font-family: 'Open Sans';
    margin: 0.8em 0 0.4em 0;
    > span {
        font-size: 1rem;
        color: black;
    }
`;

const PropertyDetailsList = styled.ol`
    list-style: disc inside none;
    > li {
        border-bottom: 1px solid black;
    }
`;

const PlainList = styled.ol`
    list-style: disc inside none;
`;

const FinePrint = styled.p`
    margin: 0.4em 0;
    color: red;
    > span {
        color: black;
    }
`;

const Panel = ({ textFirst, title, description, about, image, color }) => (
    <div className="columns">
        {textFirst ? (
            <React.Fragment>
                <TextBlock color={color} className="column is-4">
                    {title && <h2 className="is-size-1">{title}</h2>}
                    {description && (
                        <TextBlockDescription>
                            {description}
                        </TextBlockDescription>
                    )}
                    {about && <p>{about}</p>}
                </TextBlock>
                <Img
                    className="column is-8"
                    fluid={
                        image.childImageSharp
                            ? image.childImageSharp.fluid
                            : { src: image }
                    }
                />
            </React.Fragment>
        ) : (
            <React.Fragment>
                <Img
                    className="column is-8"
                    fluid={
                        image.childImageSharp
                            ? image.childImageSharp.fluid
                            : { src: image }
                    }
                />
                <TextBlock color={color} className="column is-4">
                    {title && <h2 className="is-size-1">{title}</h2>}
                    {description && (
                        <TextBlockDescription>
                            {description}
                        </TextBlockDescription>
                    )}
                    {about && <p>{about}</p>}
                </TextBlock>
            </React.Fragment>
        )}
    </div>
);

export const YourHomeAwayPageTemplate = ({
    title,
    image,
    subheading,
    sections,
    villaLocation,
    outdoorFeatures,
    featuresAndAmenities,
    propertyDetails,
    houseRules,
    collage
}) => (
    <div>
        <HeroContainer title={title} image={image} subheading={subheading} />
        <section className="section section--gradient">
            <div className="container">
                <Panel
                    {...sections[0].row.textPanel}
                    image={sections[0].row.image}
                    color="green"
                    textFirst
                />
                <Panel
                    {...sections[1].row.textPanel}
                    image={sections[1].row.image}
                    color="red"
                />
                <Panel
                    {...sections[2].row.textPanel}
                    image={sections[2].row.image}
                    color="green"
                    textFirst
                />
                <div className="columns">
                    <Img
                        className="column is-8"
                        fluid={
                            villaLocation.image.childImageSharp
                                ? villaLocation.image.childImageSharp.fluid
                                : { src: villaLocation.image }
                        }
                    />
                    <TextBlock
                        color="red"
                        className="column is-4"
                        style={{ color: 'white' }}
                    >
                        <h2 className="is-size-1">{villaLocation.title}</h2>
                        <h4 className="is-size-4">Points of Interest:</h4>
                        <PlainList>
                            {villaLocation.pointsOfInterest.map(
                                pointOfInterest => (
                                    <li>{pointOfInterest.distance}</li>
                                )
                            )}
                        </PlainList>
                        <h4 className="is-size-4">Airports:</h4>
                        <PlainList>
                            {villaLocation.airports.map(airport => (
                                <li>{airport.distance}</li>
                            ))}
                        </PlainList>
                    </TextBlock>
                </div>
                {/* <EmbedContainer>
                    <iframe
                        src="https://www.youtube.com/embed/ypQTUGjChWc"
                        frameBorder="0"
                        allowFullScreen
                        title="Follow Your Senses In Tuscany - YouTube"
                    ></iframe>
                </EmbedContainer> */}
            </div>
        </section>
        <section className="section section--gradient">
            <div className="container">
                <Collage collage={collage} />
            </div>
        </section>
    </div>
);

const YourHomeAwayPage = ({ data }) => {
    const { markdownRemark: post } = data;
    return (
        <Layout>
            <SEO
                title={post.frontmatter.title}
                image={post.frontmatter.image.childImageSharp.fluid.src}
                description={post.frontmatter.description}
            />
            <YourHomeAwayPageTemplate
                title={post.frontmatter.title}
                image={post.frontmatter.image}
                subheading={post.frontmatter.subheading}
                mainpitch={post.frontmatter.mainpitch}
                sections={post.frontmatter.sections}
                styledLists={post.frontmatter.styledLists}
                villaLocation={post.frontmatter.villaLocation}
                houseRules={post.frontmatter.houseRules}
                outdoorFeatures={post.frontmatter.outdoorFeatures}
                featuresAndAmenities={post.frontmatter.featuresAndAmenities}
                propertyDetails={post.frontmatter.propertyDetails}
                collage={post.frontmatter.collage}
            />
        </Layout>
    );
};

export default YourHomeAwayPage;

export const YourHomeAwayuery = graphql`
    query YourHomeAwayPage($id: String!) {
        markdownRemark(id: { eq: $id }) {
            html
            frontmatter {
                title
                image {
                    childImageSharp {
                        fluid(maxWidth: 2048, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                subheading
                sections {
                    row {
                        image {
                            childImageSharp {
                                fluid(
                                    maxHeight: 1500
                                    maxWidth: 2048
                                    quality: 100
                                ) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        textPanel {
                            title
                            description
                            about
                        }
                    }
                }
                villaLocation {
                    image {
                        childImageSharp {
                            fluid(
                                maxHeight: 920
                                maxWidth: 2048
                                quality: 100
                            ) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    title
                    pointsOfInterest {
                        distance
                    }
                    airports {
                        distance
                    }
                }
                # houseRules {
                #     image {
                #         childImageSharp {
                #             fluid(
                #                 maxHeight: 920
                #                 maxWidth: 2048
                #                 quality: 100
                #             ) {
                #                 ...GatsbyImageSharpFluid
                #             }
                #         }
                #     }
                #     rules {
                #         rule
                #     }
                #     cancellations {
                #         cancellation
                #     }
                # }
                propertyDetails {
                    title
                    image {
                        childImageSharp {
                            fluid(maxWidth: 2048, quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    # gardenRoom {
                    #     otherInfo
                    #     roomDescription
                    # }
                    # guestHouse {
                    #     roomDescription
                    # }
                    # mainHouse {
                    #     roomDescription
                    # }
                }
                outdoorFeatures {
                    title
                    image {
                        childImageSharp {
                            fluid(
                                maxHeight: 1200
                                maxWidth: 2048
                                quality: 100
                            ) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    # outdoorFeatureList {
                    #     outdoorFeature
                    # }
                }
                featuresAndAmenities {
                    title
                    image {
                        childImageSharp {
                            fluid(maxWidth: 2048, quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    # featureAmenityList {
                    #     featureAmenity
                    # }
                }
                collage {
                    image {
                        childImageSharp {
                            fluid(maxWidth: 334, quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                blurbs {
                    image {
                        childImageSharp {
                            fluid(maxWidth: 2048, quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }
`;
